import News from 'components/editorial/sections/news'
import Events from 'components/events/pages/home/sections/events'
import Magazine from 'components/editorial/pages/home/sections/magazine'
import Music from 'components/editorial/pages/home/sections/music'
import IPS from 'components/generic/ips'
import Ad from 'components/generic/ad'
import Heading from 'components/generic/heading'
import AD_TARGETING_KEYS from 'enums/ad-targeting-keys'
import { useServerTime } from 'context/ServerTimeContext'
import getISODay from 'date-fns/getISODay'
import InfiniteScrollNewsListing from 'components/editorial/sections/infinite-scroll-news-listing'
import { addMonths } from 'date-fns'
import { Stack } from '@resident-advisor/design-system'
import { PaddedStack } from 'components/generic/padded'
import { useAdTargetingDispatch } from 'hooks/useAdTargeting'
import { StickyContextProvider } from 'context/StickyContext'
import Seo from 'components/generic/seo/Seo'
import useSeo from 'hooks/useSeo'
import seo from 'messages/seo'

const Home = () => {
  const serverTime = useServerTime()
  useAdTargetingDispatch(adTargeting)

  const seoData = useSeo({
    titleMessage: {
      message: seo.homeTitle,
    },
    descriptionMessage: {
      message: seo.homeDescription,
    },
  })

  return (
    <>
      <Seo {...seoData} />
      <IPS />
      <Ad variant={Ad.variants.MPU_General_Small} bg="primary" p={2} />
      <Heading level={1} hide>
        Resident Advisor
      </Heading>
      <Stack>
        <PaddedStack>
          <News
            layout={News.Layouts.popularWrapBottom}
            AdComponent={
              <Ad
                variant={{
                  ...Ad.variants.MPU_General_2,
                  display: { s: 'none', m: 'flex' },
                }}
                mt={{ s: 4, m: 5 }}
              />
            }
          />
          <Ad variant={Ad.variants.HPTO_2} />
        </PaddedStack>
        <Events />
        <Magazine showExchange={!isMondayToWednesday(serverTime)} />
        <Music showPodcast={isMondayToWednesday(serverTime)} />
      </Stack>
      <StickyContextProvider offset={0}>
        <InfiniteScrollNewsListing
          dateFrom={addMonths(serverTime, -6)}
          dateTo={new Date(serverTime)}
          skip={4}
        />
      </StickyContextProvider>
    </>
  )
}

const isMondayToWednesday = (date) => {
  if (getISODay(date) < 4) {
    return true
  }
  return false
}

const adTargeting = [
  [AD_TARGETING_KEYS.PAGE, '/'],
  [AD_TARGETING_KEYS.CATEGORY, 'Home'],
]

export default Home
