import { useQuery } from '@apollo/client'
import { useEffect, memo, PropsWithChildren, useState, useRef } from 'react'
import { useSwipeable } from 'react-swipeable'
import styled, { css, ThemeProvider } from 'styled-components'
import {
  Box,
  Alignment,
  Layout,
  Pagination,
} from '@resident-advisor/design-system'

import { usePersonalizationContext } from 'context/PersonalizationContext'
import { useMenuContext, actions } from 'context/MenuContext'
import QueryResultHandler from 'components/generic/query-result-handler'
import TrackingExperimentVariantNames from 'enums/tracking-experiment-variant-names'
import { headers, dark, zIndex } from 'themes'
import trackingIds from 'tracking/tracking-ids'

import usePageHeight from 'hooks/usePageHeight'
import IPSItemDto from 'interfaces/gql/IPSItemDto'
import Tracking from 'scripts/tracking'
import featureSwitches from 'enums/feature-switches'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import testIds from 'enums/testIds'
import GET_IPS_ITEMS from './GetIPSItemsQuery'
import IPSItem from './IPSItem'
import useIPSCycle from './useIPSCycle'
import colorMapping from './colorMapping'

const IPS = memo(() => {
  const [{ color: menuColor }, dispatch] = useMenuContext()
  const { area, loading: contextLoading } = usePersonalizationContext()

  const { variant } = Tracking.useTrackingExperiment(
    featureSwitches.hideIPSExperiment
  )

  const { loading, error, data } = useQuery(GET_IPS_ITEMS, {
    variables: { countryId: parseInt(area?.country?.id, 10) },
    skip: contextLoading || variant === TrackingExperimentVariantNames.On,
  })

  const ipsItems = data?.ips

  const { selectedIndex, setNext, setPrevious } = useIPSCycle(ipsItems)

  useEffect(
    () => () => {
      dispatch({ type: actions.RESET_MENU_COLOR })
    },
    [dispatch]
  )

  useEffect(() => {
    const itemColor = colorMapping[ipsItems?.[selectedIndex].textColor]

    if (itemColor && itemColor !== menuColor) {
      dispatch({
        type: actions.SET_MENU_COLOR,
        payload: itemColor,
      })
    }
  }, [menuColor, dispatch, ipsItems, selectedIndex])

  useEffect(() => {
    // The global nav normally has a transparent background that is rendered on top of the IPS.
    // When we hide the IPS, change the global nav to have a background color.
    if (variant === TrackingExperimentVariantNames.On) {
      dispatch({
        type: actions.SET_HAS_BACKGROUND_COLOR,
        payload: true,
      })
    }
  }, [variant, dispatch])

  if (variant === TrackingExperimentVariantNames.On) {
    // Don't render IPS, instead just offset the height of the global nav
    return <Box mt={5} />
  }

  return (
    <QueryResultHandler
      loading={loading}
      error={error}
      data={data}
      dataKey="ips"
      markup={IPSMarkup}
      markupProps={{
        selectedIndex,
        setNext,
        setPrevious,
      }}
    />
  )
})

const IPSMarkup: React.FC<IPSMarkupProps> = ({
  data,
  selectedIndex,
  setNext,
  setPrevious,
}: IPSMarkupProps) => {
  const isMounted = useRef(false)
  const pageHeight = usePageHeight()
  const [height, setHeight] = useState('100vh')
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)
  const [{ globalNavHeight }] = useMenuContext()

  useEffect(() => {
    if (!isMounted.current) {
      setHeight(pageHeight)
      isMounted.current = true
    }
  }, [pageHeight])

  return (
    <ThemeProvider theme={dark}>
      <IPSContainer
        data-tracking-id={trackingIds.ips}
        data-testid={testIds.ips}
        height={height}
        mt={enableNewMainNav ? globalNavHeight : 0}
      >
        <SwipeArea setNext={setNext} setPrevious={setPrevious}>
          {data.map((item, index) => (
            <IPSItem
              key={item.id}
              isFirstItem={index === 0}
              isSelected={index === selectedIndex}
              isNext={
                index === selectedIndex + 1 ||
                (selectedIndex === 0 && index === data.length - 1)
              }
              {...item}
            />
          ))}
          <PaginationPosition>
            <Layout>
              <PaginationContainer
                alignItems={{ s: 'flex-start', m: 'flex-end' }}
                flexDirection="column"
                pb={headers.ips.pb}
                mb={enableNewMainNav ? globalNavHeight : 0}
              >
                <Pagination
                  color={data[selectedIndex].textColor}
                  total={data.length}
                  current={selectedIndex + 1}
                  next={setNext}
                  previous={setPrevious}
                  pb={{ s: 0, m: 2 }}
                />
              </PaginationContainer>
            </Layout>
          </PaginationPosition>
        </SwipeArea>
      </IPSContainer>
    </ThemeProvider>
  )
}

type IPSMarkupProps = {
  data: IPSItemDto[]
  selectedIndex: number
  setNext: () => void
  setPrevious: () => void
}

const IPSContainer = styled(Alignment)`
  position: relative;
  z-index: ${zIndex.neutral};

  ${({ height }) =>
    css`
      height: ${height};
    `};
`

const SwipeArea: React.FC<PropsWithChildren<SwipeAreaProps>> = ({
  setNext,
  setPrevious,
  children,
}: PropsWithChildren<SwipeAreaProps>) => {
  const swipeHandlers = useSwipeable({
    onSwipedLeft: setNext, // swipe left to go forwards
    onSwipedRight: setPrevious, // swipe right to go back
    preventDefaultTouchmoveEvent: true,
  })

  return (
    <div style={{ width: '100%' }} {...swipeHandlers} data-testid="swipe-area">
      {children}
    </div>
  )
}

type SwipeAreaProps = {
  setNext: () => void
  setPrevious: () => void
}

const PaginationContainer = styled(Alignment)`
  ${({ theme }) =>
    css`
      z-index: ${theme.zIndex.ipsPagination};
    `};

  & > * {
    pointer-events: auto;
  }
`

const PaginationPosition = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  pointer-events: none;
`

export { IPSMarkup, SwipeArea }
export default IPS
