import { useState } from 'react'
import useInterval from 'hooks/useInterval'

const ITEM_DURATION = 5000

const useIPSCycle = (ipsItems) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const moveForwards = () =>
    setSelectedIndex(
      selectedIndex >= ipsItems?.length - 1 ? 0 : selectedIndex + 1
    )

  const moveBackwards = () =>
    setSelectedIndex(
      selectedIndex === 0 ? ipsItems?.length - 1 : selectedIndex - 1
    )

  const [resetInterval] = useInterval(moveForwards, ITEM_DURATION)

  const setNext = () => {
    moveForwards()
    resetInterval()
  }

  const setPrevious = () => {
    moveBackwards()
    resetInterval()
  }

  return { selectedIndex, setNext, setPrevious }
}

export default useIPSCycle
