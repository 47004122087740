import { gql } from '@apollo/client'

const LATEST_PODCAST = gql`
  query LATEST_PODCAST {
    podcasts(limit: 1) {
      id
      imageUrl
      contentUrl
      title
      translation {
        blurb
      }
    }
  }
`

export default LATEST_PODCAST
