import { useState } from 'react'
import { useIntl } from 'react-intl'
import {
  variants,
  Alignment,
  Section,
  Text,
  LoadingSpinner,
} from '@resident-advisor/design-system'
import { ThemeProvider } from 'styled-components'
import Ad from 'components/generic/ad'
import { setEventsAreaCookie } from 'lib/setAreaCookies'
import messages from 'messages/events'
import { dark } from 'themes'
import { generateUrlForAreaAndCountry } from 'lib/utils'
import Flag from 'components/shared/flag'
import EventsPicks from 'components/events/sub-sections/events-picks'
import PopularEvents from 'components/events/sub-sections/popular'
import { usePersonalizationContext } from 'context/PersonalizationContext'
import trackingIds from 'tracking/tracking-ids'
import arrayHasData from 'lib/arrayHasData'
import { PaddedStack } from 'components/generic/padded'
import LinkButton from 'components/generic/link-button'
import { eventsByAreaRoute } from 'lib/routes'
import useLocalisedAreaName from 'hooks/useLocalisedAreaName'

const useHasEvents = (count = 1) => {
  const [hasEvents, setHasEvents] = useState(true)

  return [
    hasEvents,
    (result) => {
      setHasEvents(arrayHasData(result?.eventListings.data, count))
    },
  ]
}

const Events = () => {
  const intl = useIntl()
  const { loading: areaLoading, area } = usePersonalizationContext()
  const [hasPopularEvents, popularEventsCallback] = useHasEvents()
  const [hasEventsPicks, eventsPicksCallback] = useHasEvents()

  const localisedAreaName = useLocalisedAreaName({
    area,
    country: area.country,
  })

  if (areaLoading) {
    return <LoadingSpinner />
  }

  if (!hasPopularEvents && !hasEventsPicks) {
    return null
  }

  return (
    <ThemeProvider theme={dark}>
      <Section
        data-tracking-id={trackingIds.homeEvents}
        variant={variants.section.secondary}
        alignItems="center"
        TitleComponent={() => (
          <>
            <Flag size={{ s: 42, l: 58 }} urlCode={area.country?.urlCode} />
            <Text variant={variants.text.stat} ml={{ s: 2, l: 3 }}>
              {localisedAreaName}
            </Text>
          </>
        )}
      >
        <PaddedStack>
          <PopularEvents
            area={area}
            title={intl.formatMessage(messages.popularEvents)}
            onQueryCompleted={popularEventsCallback}
          />
          <EventsPicks
            area={area}
            title={intl.formatMessage(messages.RAPicks)}
            onQueryCompleted={eventsPicksCallback}
          />
          <Alignment justifyContent="center">
            <LinkButton
              variant={variants.button.secondary}
              {...eventsByAreaRoute(
                generateUrlForAreaAndCountry('/events', area)
              )}
              onClick={() => setEventsAreaCookie(area.id)}
            >
              {intl.formatMessage(messages.viewMoreEvents)}
            </LinkButton>
          </Alignment>
          <Ad variant={Ad.variants.HPTO_3} />
        </PaddedStack>
      </Section>
    </ThemeProvider>
  )
}

export default Events
