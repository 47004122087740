import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Section, SubSection, variants } from '@resident-advisor/design-system'
import LatestFeatures from 'components/editorial/sub-sections/latest-features'
import LatestPodcastOrExchange from 'components/editorial/sub-sections/latest-podcast-or-exchange'
import messages from 'messages/magazine'
import trackingIds from 'tracking/tracking-ids'
import { PaddedStack } from 'components/generic/padded'
import Ad from 'components/generic/ad'
import VideosSubSection from './VideosSubSection'

const Magazine = ({ showExchange }) => {
  const intl = useIntl()
  return (
    <Section
      title={intl.formatMessage(messages.magazine)}
      data-tracking-id={trackingIds.homeMagazine}
    >
      <PaddedStack>
        <LatestFeatures title={intl.formatMessage(messages.features)} />
        <VideosSubSection />
        {showExchange && (
          <SubSection.Stacked variant={variants.section.secondary}>
            <LatestPodcastOrExchange type="exchange" />
          </SubSection.Stacked>
        )}
        <Ad variant={Ad.variants.HPTO_4} />
      </PaddedStack>
    </Section>
  )
}

Magazine.propTypes = {
  showExchange: PropTypes.bool,
}

export default Magazine
