import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import QueryResultHandler from 'components/generic/query-result-handler'
import MusicCard from 'components/editorial/cards/music'
import ViewMoreButton from 'components/generic/view-more-button'
import {
  SubSection,
  Grid,
  Column,
  variants,
} from '@resident-advisor/design-system'
import Ad from 'components/generic/ad'
import messages from 'messages/music'
import { exchangeByIdRoute, podcastByIdRoute } from 'lib/routes'
import LATEST_EXCHANGE from './GetLatestExchange'
import LATEST_PODCAST from './GetLatestPodcast'

const LatestPodcastOrExchange = ({ type }) => {
  const queryResult = useQuery(queries[type])

  return (
    <QueryResultHandler
      {...queryResult}
      dataKey={dataKey[type]}
      markup={LatestPodcastOrExchangeMarkup}
      type={type}
    />
  )
}

const LatestPodcastOrExchangeMarkup = ({ data, type }) => {
  const intl = useIntl()

  const podcastOrExchange = data[0]
  const urlFormatter =
    type === 'exchange' ? exchangeByIdRoute : podcastByIdRoute

  return (
    <SubSection.Stacked
      title={intl.formatMessage(title[type])}
      variant={
        type === 'exchange'
          ? variants.section.primary
          : variants.section.secondary
      }
    >
      <Grid mCols={12} sCols={1} mWrap sWrap>
        <Column mSpan={5} sSpan={1}>
          <MusicCard
            {...podcastOrExchange}
            urlFormatter={urlFormatter}
            Button={
              <ViewMoreButton href={`/${type}`}>
                {intl.formatMessage(viewMoreText[type])}
              </ViewMoreButton>
            }
          />
        </Column>
        <Column mSpan={7}>
          <Ad variant={Ad.variants.MPU_General_Large} />
        </Column>
      </Grid>
    </SubSection.Stacked>
  )
}

const title = {
  exchange: messages.exchange,
  podcast: messages.podcast,
}

const queries = {
  exchange: LATEST_EXCHANGE,
  podcast: LATEST_PODCAST,
}

const dataKey = {
  exchange: 'exchanges',
  podcast: 'podcasts',
}

const viewMoreText = {
  exchange: messages.viewMoreExchanges,
  podcast: messages.viewMorePodcasts,
}

LatestPodcastOrExchangeMarkup.propTypes = {
  data: PropTypes.array.isRequired,
  type: PropTypes.oneOf(['podcast', 'exchange']).isRequired,
}

LatestPodcastOrExchange.propTypes = {
  type: PropTypes.oneOf(['podcast', 'exchange']).isRequired,
}

export { LatestPodcastOrExchangeMarkup }
export default LatestPodcastOrExchange
