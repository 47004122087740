import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import MusicCard from 'components/editorial/cards/music'
import messages from 'messages/music'
import { reviewByIdRoute } from 'lib/routes'
import arrayHasData from 'lib/arrayHasData'

const EPReview = ({ data }) => {
  const intl = useIntl()

  if (!arrayHasData(data)) {
    return null
  }

  const single = data[0]

  return (
    <MusicCard
      urlFormatter={reviewByIdRoute}
      contentTitle={intl.formatMessage(messages.epReview)}
      {...single}
      musicType="EP Review"
    />
  )
}

EPReview.propTypes = {
  data: PropTypes.array.isRequired,
}

EPReview.displayName = 'EPReview'

export default EPReview
