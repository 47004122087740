import { useQuery } from '@apollo/client'
import QueryResultHandler from 'components/generic/query-result-handler'
import YoutubePlayer from 'components/generic/youtube-player'
import FeaturedItem from 'components/generic/featured-item'
import { useIntl } from 'react-intl'
import { SubSection } from '@resident-advisor/design-system'
import PropTypes from 'prop-types'
import messages from 'messages/magazine'
import { toDateFormat } from 'lib/dateFormat'
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
import ViewMoreButton from 'components/generic/view-more-button'
import GET_FEATURED_VIDEO from './GetFeaturedVideoQuery'

const getDateRange = () => {
  const today = format(Date.now(), 'yyyy-MM-dd')

  return {
    dateFrom: addDays(toDateFormat(today), -5),
    dateTo: today,
  }
}

const VideosSubSection = () => {
  const queryResult = useQuery(GET_FEATURED_VIDEO, {
    variables: {
      ...getDateRange(),
    },
  })

  return (
    <QueryResultHandler
      {...queryResult}
      dataKey="features"
      markup={VideosMarkup}
    />
  )
}

const VideosMarkup = ({ data }) => {
  const intl = useIntl()

  return (
    <SubSection.Stacked title={intl.formatMessage(messages.films)}>
      <FeaturedItem
        Media={YoutubePlayer}
        Button={
          <ViewMoreButton href="/films">
            {intl.formatMessage(messages.viewMoreFilms)}
          </ViewMoreButton>
        }
        item={data[0]}
      />
    </SubSection.Stacked>
  )
}

VideosMarkup.propTypes = {
  data: PropTypes.array.isRequired,
}

export { VideosMarkup }
export default VideosSubSection
