import { gql } from '@apollo/client'

const GET_IPS_ITEMS = gql`
  query GET_IPS_ITEMS($countryId: ID!) {
    ips(countryId: $countryId) {
      id
      title
      blurb
      contentUrl
      imageUrl
      imageUrlMobile
      textColor
    }
  }
`

export default GET_IPS_ITEMS
