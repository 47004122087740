import {
  Box,
  Alignment,
  Text,
  Icon,
  ExternalLinkIcon,
  SubSection,
  variants,
  colors,
} from '@resident-advisor/design-system'
import pro from 'messages/pro'
import { useIntl } from 'react-intl'
import LinkButton from 'components/generic/link-button'
import getConfig from 'next/config'
import Tracking, { TRACKING_EVENT } from 'scripts/tracking'

const {
  publicRuntimeConfig: { RA_BUMPS_CONFIG, PUBLIC_ASSETS_BASE_PATH },
} = getConfig()

const RABumpsAd = ({ page }: { page: string }) => {
  const intl = useIntl()

  Tracking.trackMixpanel(TRACKING_EVENT.raBumpsAdShown, {
    Page: page,
  })

  const raBumpsLink: string = RA_BUMPS_CONFIG.RA_BUMPS_LANDING_PAGE

  return (
    <SubSection pt={4}>
      <Alignment
        py={3}
        justifyContent="space-between"
        display={{ s: 'block', m: 'flex' }}
        alignItems="center"
        backgroundImage={{
          s: `url("${PUBLIC_ASSETS_BASE_PATH}/bumps/ra-bumps-ad-s.jpg")`,
          m: `url("${PUBLIC_ASSETS_BASE_PATH}/bumps/ra-bumps-ad-m.jpg")`,
          l: `url("${PUBLIC_ASSETS_BASE_PATH}/bumps/ra-bumps-ad-l.jpg")`,
          xl: `url("${PUBLIC_ASSETS_BASE_PATH}/bumps/ra-bumps-ad-xl.jpg")`,
        }}
      >
        <Box px={4}>
          <Text variant={variants.text.heading.pro.l} color={colors.white}>
            {intl.formatMessage(pro.bumpYourEvent)}
          </Text>
        </Box>
        <LinkButton
          variant={variants.button.primaryWhite}
          href={raBumpsLink}
          target="__blank"
          mr={{ s: 0, m: 5 }}
          ml={{ s: 4, m: 0 }}
          mt={{ s: 3, m: 0 }}
          onClick={() =>
            Tracking.trackMixpanel(TRACKING_EVENT.clickRABumpsAd, {
              Page: page,
            })
          }
        >
          <Icon Component={ExternalLinkIcon} pr={1} />
          {intl.formatMessage(pro.learnMore)}
        </LinkButton>
      </Alignment>
    </SubSection>
  )
}

RABumpsAd.propTypes = {}

export default RABumpsAd
