import { useEffect } from 'react'
import { useFeatureSwitchesContext } from 'context/FeatureSwitchesContext'

const useSetRaBumpsAdContext = (areaId: string | number | undefined) => {
  const { updateContext } = useFeatureSwitchesContext()

  useEffect(() => {
    if (areaId) {
      updateContext({
        properties: {
          eventAreaId: areaId.toString(),
        },
      })
    }
  }, [areaId, updateContext])
}

export default useSetRaBumpsAdContext
