import { useEffect, useRef } from 'react'

const useInterval = (callback, delay) => {
  const interval = useRef()
  const savedCallback = useRef()

  const tick = () => savedCallback.current()

  const reset = () => {
    clearInterval(interval.current)
    interval.current = setInterval(tick, delay)
  }

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    interval.current = setInterval(tick, delay)
    return () => clearInterval(interval.current)
  }, [delay])

  return [reset]
}

export default useInterval
