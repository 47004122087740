import { gql } from '@apollo/client'
import EVENT_LISTINGS_FRAGMENT from 'components/events/pages/events-home/event-listings/queries/EventListingsFragment'

const GET_EVENTS_PICKS = gql`
  query GET_EVENTS_PICKS(
    $filters: FilterInputDtoInput
    $filterOptions: FilterOptionsInputDtoInput
    $page: Int
    $pageSize: Int
  ) {
    eventListings(
      filters: $filters
      filterOptions: $filterOptions
      pageSize: $pageSize
      page: $page
      sort: { interestedCount: { priority: 1, order: DESCENDING } }
    ) {
      data {
        id
        event {
          ...eventListingsFields
          artists {
            id
            name
          }
        }
      }
    }
  }
  ${EVENT_LISTINGS_FRAGMENT}
`

export default GET_EVENTS_PICKS
