import { gql } from '@apollo/client'

const LATEST_EXCHANGE = gql`
  query LATEST_EXCHANGE {
    exchanges(limit: 1) {
      blurb
      id
      imageUrl
      contentUrl
      title
      isTheHour
    }
  }
`

export default LATEST_EXCHANGE
