import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { format, endOfWeek } from 'date-fns'
import { ThemeProvider } from 'styled-components'
import { dark } from 'themes'
import QueryResultHandler from 'components/generic/query-result-handler'
import HorizontalList from 'components/generic/horizontal-list'
import { useIntl } from 'react-intl'
import { SubSection } from '@resident-advisor/design-system'
import EventPickCard from 'components/events/cards/event-pick'
import messages from 'messages/events'
import FilterInputDto from 'interfaces/gql/FilterInputDto'
import AreaDto from 'interfaces/gql/AreaDto'
import { useServerTime } from 'context/ServerTimeContext'
import useLocalisedAreaName from 'hooks/useLocalisedAreaName'
import { Fields } from 'enums/listing'
import useAddLocationFilter from 'components/events/pages/events-home/event-listings/event-listings-filter-context/useAddLocationFilter'
import GET_EVENTS_PICKS from './EventsPicksQuery'

const EventsPicks = ({
  area,
  title: propTitle,
  onQueryCompleted = () => ({}),
  ...props
}: EventsPicksProps) => {
  const intl = useIntl()
  const serverTime = useServerTime()

  const dateFrom = format(new Date(serverTime), 'yyyy-MM-dd')
  const dateTo = format(
    endOfWeek(new Date(dateFrom), { weekStartsOn: 1 }),
    'yyyy-MM-dd'
  )

  const initialFilters: FilterInputDto = {
    [Fields.Areas]: { eq: parseInt(area.id, 10) },
    [Fields.ListingDate]: {
      gte: dateFrom,
      lte: dateTo,
    },
    [Fields.ListingPosition]: { eq: 1 },
    [Fields.IsPick]: { eq: true },
  }

  const filters = useAddLocationFilter(initialFilters)

  const queryProps = useQuery(GET_EVENTS_PICKS, {
    variables: {
      filters,
      pageSize: 10,
    },
    onCompleted: onQueryCompleted,
  })

  const localisedAreaName = useLocalisedAreaName({
    area,
    country: area.country,
  })

  const title =
    propTitle ||
    (area.name
      ? intl.formatMessage(messages.RAPicksWithLocation, {
          areaName: localisedAreaName,
        })
      : intl.formatMessage(messages.RAPicks))

  return (
    <QueryResultHandler
      {...queryProps}
      dataKey="eventListings.data"
      markup={EventsPicksSubSection}
      markupProps={{
        title,
      }}
      {...props}
    />
  )
}

type EventsPicksProps = {
  area?: AreaDto
  title?: string
  onQueryCompleted?: () => void
}

const EventsPicksSubSection = ({
  data,
  title,
  ...props
}: EventsPicksSubSectionProps) => (
  <ThemeProvider theme={dark}>
    <SubSection.Stacked title={title} variant="secondary" {...props}>
      <HorizontalList
        Card={EventPickCard}
        mCols={4}
        items={data.filter((x) => x.event).map((item) => item.event)}
      />
    </SubSection.Stacked>
  </ThemeProvider>
)

type EventsPicksSubSectionProps = {
  data: { event: object }[]
  title: string
}

EventsPicksSubSection.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}

const ORDER_BY_TYPE = {
  THISWEEK: 'THISWEEK',
  LATEST: 'LATEST',
}

EventsPicks.ORDER_BY_TYPE = ORDER_BY_TYPE

export { EventsPicksSubSection }
export default EventsPicks
