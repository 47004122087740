import { useState, useEffect, memo } from 'react'
import styled, { css } from 'styled-components'
import {
  Box,
  Layout,
  Text,
  Alignment,
  variants,
} from '@resident-advisor/design-system'

import { headers, zIndex } from 'themes'
import Link from 'components/generic/link'
import { FadeIn } from 'components/shared/animation'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'
import { useMenuContext } from 'context/MenuContext'
import { PreloadFirstItem } from './item-components/PreloadFirstItem'
import { HeroImage } from './item-components/HeroImage'
import colorMapping from './colorMapping'

const IPSItem = memo<IPSItemProps>(
  ({
    isSelected,
    isNext,
    isFirstItem,
    contentUrl,
    imageUrl,
    imageUrlMobile,
    textColor,
    title,
    blurb,
  }: IPSItemProps) => {
    const [loaded, setLoaded] = useState(isSelected || isNext)
    const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)
    const [{ globalNavHeight }] = useMenuContext()

    useEffect(() => {
      if (!loaded && (isSelected || isNext)) {
        setLoaded(true)
      }
    }, [loaded, isSelected, isNext])

    return (
      <>
        <PreloadFirstItem
          isFirstItem={isFirstItem}
          imageUrl={imageUrl}
          imageUrlMobile={imageUrlMobile}
        />
        <Link href={contentUrl}>
          <FadeIn animate={isSelected ? 'open' : 'closed'}>
            <ContentWrapper height="100%" isSelected={isSelected}>
              <HeroImage
                imageUrl={imageUrl}
                imageUrlMobile={imageUrlMobile}
                loaded={loaded}
              />
              {loaded && (
                <Layout style={{ height: '100%' }}>
                  <TextWrapper
                    alignItems={{ s: 'flex-start', m: 'flex-end' }}
                    flexDirection={{ s: 'column', m: 'row' }}
                    pt={headers.ips.pt}
                    pb={headers.ips.pb}
                    isSelected={isSelected}
                    mb={enableNewMainNav ? globalNavHeight : 0}
                  >
                    <Alignment flexDirection="column">
                      <Text
                        variant={variants.text.heading.xl}
                        color={colorMapping[textColor]}
                      >
                        {title}
                      </Text>
                      <Text
                        variant={variants.text.heading.xl}
                        color={colorMapping[textColor]}
                      >
                        {blurb}
                      </Text>
                    </Alignment>
                  </TextWrapper>
                </Layout>
              )}
            </ContentWrapper>
          </FadeIn>
        </Link>
      </>
    )
  }
)

type IPSItemProps = {
  isFirstItem: boolean
  isSelected: boolean
  isNext: boolean
  contentUrl: string
  imageUrl: string
  imageUrlMobile: string
  textColor: string
  title: string
  blurb: string
}

const ContentWrapper = styled(Box)`
  width: 100%;
  position: absolute;
  top: 0;
  background-color: white;

  ${({ isSelected }) =>
    isSelected
      ? css`
          z-index: ${zIndex.ipsSelected};
        `
      : css`
          z-index: ${zIndex.ipsUnselected};
        `}
`

const TextWrapper = styled(Alignment)`
  box-sizing: border-box;
  justify-content: space-between;

  ${({ isSelected }) =>
    isSelected
      ? css`
          z-index: ${zIndex.ipsSelected};
          display: flex;
        `
      : css`
          z-index: ${zIndex.ipsUnselected};
          display: none;
        `}
`

export default IPSItem
