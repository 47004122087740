import { useQuery } from '@apollo/client'
import QueryResultHandler from 'components/generic/query-result-handler'
import { useIntl } from 'react-intl'
import AreaDto from 'interfaces/gql/AreaDto'
import messages from 'messages/events'
import { useServerTime } from 'context/ServerTimeContext'
import { format, endOfWeek } from 'date-fns'
import FilterInputDto from 'interfaces/gql/FilterInputDto'
import useLocalisedAreaName from 'hooks/useLocalisedAreaName'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'
import { Fields, SORT_ORDER } from 'enums/listing'
import SortInputDto from 'interfaces/gql/SortInputDto'
import useAddLocationFilter from 'components/events/pages/events-home/event-listings/event-listings-filter-context/useAddLocationFilter'
import EventDto from 'interfaces/gql/EventDto'
import EventListingBumpDto from 'interfaces/gql/EventListingBumpDto'
import { insertBumpedPopularEvents } from 'lib/insertBumpedEvents'
import arrayHasData from 'lib/arrayHasData'
import { useSetRaBumpsAdContext } from 'components/pro/shared/ra-bumps-ad'
import PopularEventsSubSectionMarkup from './PopularEventsSubSectionMarkup'
import GET_POPULAR_EVENTS from './PopularEventsQuery'

const PopularEventsSubSection = ({
  area,
  count = 10,
  title: titleProp,
  dateFrom: dateFromProp,
  onQueryCompleted = () => ({}),
  ...props
}: PopularEventsSubSectionProps) => {
  const intl = useIntl()
  const serverTime = useServerTime()
  useSetRaBumpsAdContext(area?.id)
  const enableWeightedEventListings = useFeatureSwitch(
    featureSwitches.enableWeightedEventListings
  )
  const enablePopularBumpsInWeb = useFeatureSwitch(
    featureSwitches.enablePopularBumpsInWeb
  )
  const dateFrom = dateFromProp || format(new Date(serverTime), 'yyyy-MM-dd')
  const dateTo = format(
    endOfWeek(new Date(dateFrom), { weekStartsOn: 1 }),
    'yyyy-MM-dd'
  )

  const initialFilters: FilterInputDto = {
    [Fields.Areas]: { eq: parseInt(area?.id, 10) },
    [Fields.ListingDate]: {
      gte: dateFrom,
      lte: dateTo,
    },
    [Fields.ListingPosition]: { eq: 1 },
  }
  const filters = useAddLocationFilter(initialFilters)
  let bumpOptions = {}
  if (enablePopularBumpsInWeb && !!area?.id) {
    bumpOptions = {
      areaId: area.id,
      dateRange: { startDate: dateFrom, endDate: dateTo },
      type: 'POPULAR',
    }
  }

  const sort: SortInputDto | null = enableWeightedEventListings
    ? {
        score: { order: SORT_ORDER.Descending },
        titleKeyword: { order: SORT_ORDER.Ascending },
      }
    : { interestedCount: { priority: 1, order: SORT_ORDER.Descending } }

  const queryProps = useQuery(GET_POPULAR_EVENTS, {
    variables: {
      filters,
      pageSize: count,
      sort,
      includeBumps: enablePopularBumpsInWeb && !!area.id,
      ...bumpOptions,
    },
    onCompleted: onQueryCompleted,
  })

  const localisedAreaName = useLocalisedAreaName({
    area,
    country: area?.country,
  })

  const title =
    titleProp ||
    (area?.name
      ? intl.formatMessage(messages.popularWithLocation, {
          areaName: localisedAreaName,
        })
      : intl.formatMessage(messages.popular))

  return (
    <QueryResultHandler
      {...queryProps}
      dataKey="eventListings.data"
      markup={PopularEventsSubSectionWrapper}
      markupProps={{
        title,
        bumps: queryProps.data?.bumps?.bumpDecision,
      }}
      {...props}
    />
  )
}

type PopularEventsSubSectionProps = {
  area?: AreaDto
  count?: number
  initialPageNumber?: number
  title?: string
  dateFrom?: string
  onQueryCompleted?: () => void
}

const PopularEventsSubSectionWrapper = ({
  data,
  title,
  bumps,
  SubmitEventButton,
  ...props
}: PopularEventsSubSectionWrapperProps) => {
  // Filter out events without event data
  let filteredData = data.filter((x) => x.event).map((item) => item.event)

  if (arrayHasData(bumps)) {
    filteredData = insertBumpedPopularEvents(filteredData, bumps)
  }

  return (
    <PopularEventsSubSectionMarkup
      data={filteredData}
      title={title}
      SubmitEventButton={SubmitEventButton}
      {...props}
    />
  )
}

type PopularEventsSubSectionWrapperProps = {
  data: { event: EventDto }[]
  title: string
  bumps?: EventListingBumpDto[]
  SubmitEventButton?: () => JSX.Element
}

export default PopularEventsSubSection
