import { gql } from '@apollo/client'

const GET_FEATURED_VIDEO = gql`
  query GET_FEATURED_VIDEO($dateFrom: DateTime, $dateTo: DateTime) {
    features(
      type: FILM
      limit: 1
      featuredOnly: true
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      id
      title
      blurb
      imageUrl
      contentUrl
      youtubeId
      series {
        id
        seriesRef
        title
        contentUrl
      }
    }
  }
`
export default GET_FEATURED_VIDEO
